define("ember-scanner/components/code-scanner", ["exports", "@ember/component", "@ember-decorators/object", "@ember-decorators/component", "ember-scanner/templates/code-scanner"], function (_exports, _component, _object, _component2, _codeScanner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let CodeScanner = (_dec = (0, _component2.layout)(_codeScanner.default), _dec2 = (0, _component2.tagName)('video'), _dec3 = (0, _component2.classNames)('code-scanner'), _dec4 = (0, _object.observes)('device'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class CodeScanner extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "device", undefined);
    }

    didInsertElement() {
      super.didInsertElement(...arguments);
      emberAutoImportDynamic("@zxing/library").then(module => {
        if (this.isDestroyed) return;
        this.set('codeReader', new module.BrowserMultiFormatReader());
        this.startVideoScanning();
        this.codeReader.getVideoInputDevices().then(videoInputDevices => {
          if (this.onDevicesFound) {
            this.onDevicesFound(videoInputDevices);
          }
        }).catch(error => {
          if (this.onDevicesError) {
            this.onDevicesError(error);
          }
        });
      });
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);

      if (this.codeReader) {
        this.codeReader.reset();
      }
    } // eslint-disable-next-line ember/no-observers


    onDeviceChange() {
      this.codeReader.reset();
      this.startVideoScanning();
    }

    startVideoScanning() {
      this.codeReader.decodeFromInputVideoDevice(this.device, this.elementId).then(result => {
        if (this.isDestroyed) return;

        if (this.onScanSuccess) {
          this.onScanSuccess(result);
        }
      }).catch(error => {
        if (this.isDestroyed) return;

        if (this.onScanError) {
          this.onScanError(error);
        }
      });
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "onDeviceChange", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "onDeviceChange"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  var _default = CodeScanner;
  _exports.default = _default;
});