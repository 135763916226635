define("ember-promise-helpers/helpers/promise-all", ["exports", "@ember/component/helper", "rsvp"], function (_exports, _helper, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _helper.default {
    compute(params) {
      const args = Array.isArray(params[0]) ? params[0] : params;
      return _rsvp.default.all(args);
    }

  }

  _exports.default = _default;
});