define("@fortawesome/ember-fontawesome/components/fa-icon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@fortawesome/fontawesome-svg-core", "@ember/template", "ember-get-config"], function (_exports, _component, _templateFactory, _component2, _fontawesomeSvgCore, _template, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{~#if this.iconExists~}}
      <svg
          style={{this.safeStyle}}
          class={{this.iconAttributes.class}}
          data-prefix={{this.dataPrefix}}
          data-icon={{this.dataIcon}}
          data-fa-transform={{this.dataFaTransform}}
          data-fa-mask={{this.dataFaMask}}
          data-fa-processed={{this.dataFaProcessed}}
          aria-hidden={{this.ariaHidden}}
          aria-labelledby={{this.ariaLabelledBy}}
          focusable={{this.iconAttributes.focusable}}
          role={{this.iconAttributes.role}}
          xmlns={{this.iconAttributes.xmlns}}
          viewBox={{this.viewBox}}
          ...attributes
      >
          {{this.content}}
      </svg>
  {{~/if~}}
  
  */
  {
    "id": "979iFw94",
    "block": "[[[41,[30,0,[\"iconExists\"]],[[[11,\"svg\"],[16,5,[30,0,[\"safeStyle\"]]],[16,0,[30,0,[\"iconAttributes\",\"class\"]]],[16,\"data-prefix\",[30,0,[\"dataPrefix\"]]],[16,\"data-icon\",[30,0,[\"dataIcon\"]]],[16,\"data-fa-transform\",[30,0,[\"dataFaTransform\"]]],[16,\"data-fa-mask\",[30,0,[\"dataFaMask\"]]],[16,\"data-fa-processed\",[30,0,[\"dataFaProcessed\"]]],[16,\"aria-hidden\",[30,0,[\"ariaHidden\"]]],[16,\"aria-labelledby\",[30,0,[\"ariaLabelledBy\"]]],[16,\"focusable\",[30,0,[\"iconAttributes\",\"focusable\"]]],[16,\"role\",[30,0,[\"iconAttributes\",\"role\"]]],[16,\"xmlns\",[30,0,[\"iconAttributes\",\"xmlns\"]],\"http://www.w3.org/2000/xmlns/\"],[16,\"viewBox\",[30,0,[\"viewBox\"]]],[17,1],[12],[1,\"\\n        \"],[1,[30,0,[\"content\"]]],[1,\"\\n    \"],[13]],[]],null]],[\"&attrs\"],false,[\"if\"]]",
    "moduleName": "@fortawesome/ember-fontawesome/components/fa-icon.hbs",
    "isStrictMode": false
  });

  function objectWithKey(key, value) {
    return Array.isArray(value) && value.length > 0 || !Array.isArray(value) && value ? {
      [key]: value
    } : {};
  }

  function normalizeIconArgs(prefix, icon) {
    const defaultPrefix = _emberGetConfig.default?.fontawesome?.defaultPrefix ?? 'fas';

    if (!icon) {
      return {
        prefix: defaultPrefix,
        iconName: null
      };
    }

    if (typeof icon === 'object' && icon.prefix && icon.iconName) {
      return icon;
    }

    if (_fontawesomeSvgCore.parse.icon) {
      if (typeof prefix === 'string' && typeof icon === 'string') {
        return _fontawesomeSvgCore.parse.icon({
          prefix: prefix,
          iconName: icon
        });
      }

      if (typeof icon === 'string') {
        return _fontawesomeSvgCore.parse.icon({
          prefix: defaultPrefix,
          iconName: icon
        });
      }
    }

    if (typeof prefix === 'string' && typeof icon === 'string') {
      return {
        prefix: prefix,
        iconName: icon
      };
    }

    if (typeof icon === 'string') {
      return {
        prefix: defaultPrefix,
        iconName: icon
      };
    }
  }

  class FaIconComponent extends _component2.default {
    get content() {
      const children = this.abstractIcon?.children ?? [];
      const html = children.reduce((acc, cur) => {
        return `${acc}${(0, _fontawesomeSvgCore.toHtml)(cur)}`;
      }, '');
      return (0, _template.htmlSafe)(html);
    }

    get safeStyle() {
      return this.iconAttributes.style ? (0, _template.htmlSafe)(`${this.iconAttributes.style}`) : undefined;
    }

    get iconExists() {
      return Boolean(this.abstractIcon);
    }

    get flipHorizontal() {
      return this.args.flip === 'horizontal' || this.args.flip === 'both';
    }

    get flipVertical() {
      return this.args.flip === 'vertical' || this.args.flip === 'both';
    }

    get classList() {
      let classes = {
        'fa-spin': this.args.spin,
        'fa-pulse': this.args.pulse,
        'fa-fw': this.args.fixedWidth,
        'fa-border': this.args.border,
        'fa-li': this.args.listItem,
        'fa-flip-horizontal': this.flipHorizontal,
        'fa-flip-vertical': this.flipVertical,
        [`fa-${this.args.size}`]: this.args.size,
        [`fa-rotate-${this.args.rotation}`]: this.args.rotation,
        [`fa-pull-${this.args.pull}`]: this.args.pull
      };
      return Object.keys(classes).filter(key => classes[key]);
    }

    get abstractIcon() {
      const iconLookup = normalizeIconArgs(this.args.prefix, this.args.icon);
      const classes = objectWithKey('classes', this.classList);
      const transform = objectWithKey('transform', typeof this.args.transform === 'string' ? _fontawesomeSvgCore.parse.transform(this.args.transform) : this.args.transform);
      const mask = objectWithKey('mask', normalizeIconArgs(null, this.args.mask));
      const symbol = this.args.symbol ?? false;
      let title = this.args.title ? `${this.args.title}` : null;
      const o = Object.assign({}, classes, transform, mask, {
        symbol,
        title
      });
      const renderedIcon = (0, _fontawesomeSvgCore.icon)(iconLookup, o);

      if (!renderedIcon) {
        console.warn(`Could not find icon: iconName=${iconLookup.iconName}, prefix=${iconLookup.prefix}. You may need to add it to your icons.js.`);
        return null;
      }

      return renderedIcon.abstract[0];
    }

    get iconAttributes() {
      return this.abstractIcon?.attributes ?? {};
    }

    get dataPrefix() {
      return this.iconAttributes['data-prefix'];
    }

    get dataIcon() {
      return this.iconAttributes['data-icon'];
    }

    get dataFaTransform() {
      return this.iconAttributes['data-fa-transform'];
    }

    get dataFaMask() {
      return this.iconAttributes['data-fa-mask'];
    }

    get dataFaProcessed() {
      return this.iconAttributes['data-fa-processed'];
    }

    get ariaHidden() {
      return this.iconAttributes['aria-hidden'];
    }

    get ariaLabelledBy() {
      return this.iconAttributes['aria-labelledby'];
    }

    get viewBox() {
      return this.abstractIcon?.attributes?.viewBox ?? '0 0 448 512';
    }

  }

  _exports.default = FaIconComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FaIconComponent);
});