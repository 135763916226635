define("ember-wormhole/templates/components/ember-wormhole", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AZTawDXE",
    "block": "[[[1,[28,[35,0],[[30,0,[\"_wormholeHeadNode\"]]],null]],[18,1,null],[1,[28,[35,0],[[30,0,[\"_wormholeTailNode\"]]],null]]],[\"&default\"],false,[\"unbound\",\"yield\"]]",
    "moduleName": "ember-wormhole/templates/components/ember-wormhole.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});