define("ember-keyboard-shortcuts/create-mixin", ["exports", "@ember/object/mixin", "@ember/object/evented", "ember-keyboard-shortcuts/index", "@ember/application/deprecations"], function (_exports, _mixin, _evented, _index, _deprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(bindEvent, unbindEvent) {
    return _mixin.default.create({
      init() {
        this._super(...arguments);

        (false && !(false) && (0, _deprecations.deprecate)('Using ember-keyboard-shortcuts Mixin is deprecated. ' + 'Please use bindKeyboardShortcuts and unbindKeyboardShortcuts methods instead. ' + 'Usage of mixins will be removed in the next major release. ' + 'Learn more about migrating at https://github.com/Skalar/ember-keyboard-shortcuts#migrating-from-mixins', false, {
          id: 'ember-keyboard-shortcuts.mixins',
          until: '2.0.0'
        }));
      },

      bindShortcuts: (0, _evented.on)(bindEvent, function () {
        (0, _index.bindKeyboardShortcuts)(this);
      }),
      unbindShortcuts: (0, _evented.on)(unbindEvent, function () {
        (0, _index.unbindKeyboardShortcuts)(this);
      })
    });
  }
});