define("ember-keyboard-shortcuts/mixins/component", ["exports", "ember-keyboard-shortcuts/create-mixin"], function (_exports, _createMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _createMixin.default)('didInsertElement', 'willDestroyElement');

  _exports.default = _default;
});