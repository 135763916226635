define("ember-scanner/templates/code-scanner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6FJ5QSxE",
    "block": "[[[10,2],[12],[1,\"\\n  Your browser does not support this feature, please try to upgrade it.\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-scanner/templates/code-scanner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});