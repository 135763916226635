define("ember-promise-helpers/helpers/is-rejected", ["exports", "ember-promise-helpers/helpers/await"], function (_exports, _await) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends _await.default {
    compute(params) {
      const maybePromise = params[0];
      return this.ensureLatestPromise(maybePromise, async promise => {
        try {
          await promise;
          this.setValue(false, maybePromise);
        } catch (_err) {
          this.setValue(true, maybePromise);
        }
      });
    }

  }

  _exports.default = _default;
});