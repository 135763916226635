define("ember-cli-showdown/templates/components/markdown-to-html", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DA7CCqNK",
    "block": "[[[1,[30,0,[\"html\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-cli-showdown/templates/components/markdown-to-html.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});