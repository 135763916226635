define("ember-cli-qrcode/system/polynomial", ["exports", "ember-cli-qrcode/system/math"], function (_exports, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Polynomial {
    constructor(num, shift) {
      if (num.length == undefined) {
        throw new Error(num.length + "/" + shift);
      }

      let offset = 0;

      while (offset < num.length && num[offset] == 0) {
        offset++;
      }

      this.num = new Array(num.length - offset + shift);

      for (let i = 0; i < num.length - offset; i++) {
        this.num[i] = num[i + offset];
      }
    }

    get(index) {
      return this.num[index];
    }

    getLength() {
      return this.num.length;
    }

    multiply(e) {
      let num = new Array(this.getLength() + e.getLength() - 1);

      for (let i = 0; i < this.getLength(); i++) {
        for (let j = 0; j < e.getLength(); j++) {
          num[i + j] ^= (0, _math.gexp)((0, _math.glog)(this.get(i)) + (0, _math.glog)(e.get(j)));
        }
      }

      return new Polynomial(num, 0);
    }

    mod(e) {
      if (this.getLength() - e.getLength() < 0) {
        return this;
      }

      const ratio = (0, _math.glog)(this.get(0)) - (0, _math.glog)(e.get(0));
      let num = new Array(this.getLength());

      for (let i = 0; i < this.getLength(); i++) {
        num[i] = this.get(i);
      }

      for (let i = 0; i < e.getLength(); i++) {
        num[i] ^= (0, _math.gexp)((0, _math.glog)(e.get(i)) + ratio);
      }

      return new Polynomial(num, 0).mod(e);
    }

  }

  _exports.default = Polynomial;
});